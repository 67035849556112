.react-calendar {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.react-calendar.full {
  height: 100vh;
}
.react-calendar.hide-navigation .react-calendar__navigation {
  display: none;
}
.react-calendar.tile-center abbr {
  width: 1.4em;
  height: 1.4em;
  margin: 2px auto 0 auto;
  font-size: 0.9em;
  text-align: center;
}
.react-calendar.tile-center .react-calendar__month-view__weekdays {
  transform: translateY(-90%);
}
.react-calendar.tile-center .react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  border-left: 1px solid rgba(239, 239, 245, 1);
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 20px;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.dark 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #262626;
}
.react-calendar__viewContainer {
  flex: 1;
}
.react-calendar__month-view,
.react-calendar__year-view,
.react-calendar__decade-view,
.react-calendar__century-view {
  height: 100%;
}
.react-calendar__month-view > div,
.react-calendar__year-view > div,
.react-calendar__decade-view > div,
.react-calendar__century-view > div {
  height: 100%;
  align-items: flex-start !important;
  flex-direction: column;
}
.react-calendar__month-view > div > div {
  display: flex;
  flex-direction: column;
  position: relative;
}
.react-calendar__month-view__weekdays {
  position: absolute;
  width: 100%;
  flex: 1;
  text-align: right;
  font-size: 0.8em;
  color: #c2c2c2;
}
.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}
.react-calendar__month-view__days {
  flex: 1;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  border-right: 1px solid rgba(239, 239, 245, 1);
  border-bottom: 1px solid rgba(239, 239, 245, 1);
}
.react-calendar__year-view__months {
  flex: 1;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(0, 1fr);
}
.react-calendar__decade-view__years,
.react-calendar__century-view__decades {
  flex: 1;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(0, 1fr);
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  height: 100%;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: left;
  background: none;
  display: flex;
  position: relative;
  flex-direction: column;
  border-left: 1px solid rgba(239, 239, 245, 1) !important;
  border-top: 1px solid rgba(239, 239, 245, 1) !important;
  flex-shrink: 0 !important;
  color: inherit;
}
.react-calendar__tile.disabled abbr {
  color: #c2c2c2;
}
.react-calendar__tile.today::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #006edc;
  bottom: 0;
  left: 0;
}
.react-calendar__tile abbr {
  border-radius: 50%;
  width: 1.6em;
  height: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.dark .react-calendar__tile:disabled {
  background-color: #333;
}
.dark .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #262626;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--now abbr {
  background: #006edc;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
