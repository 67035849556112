@import './variable.scss';

.footer {
    position: relative;
    width: 100%;
    height: 100px;
    background-color: $tiffany;
    padding: 20px calc(100% - 1264px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $secondary;
    .mediaList {
        display: flex;
        width: 200px;
        justify-content: space-evenly;
        z-index: 1;
        .media {
            cursor: pointer;
        }
    }
    &.darkCover::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: #000000;
        opacity: 0.95;
    }
    h3 {
        width: 100%;
        z-index: 1;
        margin: 5px 0;
    }
}

@media (max-width: 1264px) {
    .footer {
        padding: 20px 10%;
    }
}