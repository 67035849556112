@import './variable.scss';

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 0 calc(100% - 1700px);
  z-index: 11;
  backdrop-filter: blur(10px);
  ul {
    font-size: 16px;
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
    li {
      position: relative;
      align-self: stretch;
      cursor: pointer;
      padding: 0 8px;
      transition: color 0.2s;
      font-size: 15px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: auto;
        padding: 0;
      }
      &:nth-child(1) {
        font-size: 18px;
        font-weight: 600;
        color: $primary;
        margin-right: auto;
      }
      span:first-child {
        color: $primary;
        font-weight: bold;
      }
      span:last-child {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $primary;
        transition: width 0.2s, color 0.2s;
      }
      &:hover {
        color: $primary;
        span:last-child {
          background-color: $primary;
          width: 100%;
        }
      }
    }
  }
  .resume {
    margin-left: 20px;
    background-color: white;
  }
  .switch {
    margin-left: 20px;
  }
  .hamburger {
    cursor: pointer;
    margin-left: auto;
  }
  .mobileMenu {
    display: table;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    transition: width 0.2s, height 0.2s;
    z-index: 1;
    &.open {
      width: 100vw;
      height: 100vh;
    }
    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .bg {
      position: fixed;
      right: 20px;
      top: 20px;
      width: 0;
      height: 0;
      background-color: $primary;
      z-index: 999;
      border-radius: 50%;
      transform: translate(50%, -50%);
      transition: width 0.2s, height 0.2s;
      &.open {
        width: 240vw;
        height: 140vh;
      }
      filter: drop-shadow(0 0 5px #00000070);
    }
    .bg1 {
      position: fixed;
      right: 20px;
      top: 20px;
      width: 0;
      height: 0;
      background-color: #00000030;
      z-index: 1000;
      border-radius: 50%;
      transform: translate(50%, -50%);
      transition: width 0.3s, height 0.3s;
      &.open {
        width: 210vw;
        height: 140vh;
      }
    }
    .closeBtn {
      position: absolute;
      top: 75%;
      left: 50%;
      z-index: 1002;
      transform: translateX(-50%);
      padding: 0;
      filter: drop-shadow(0 0 5px #00000070);
    }
    .menu {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1001;
      ul {
        display: block;
        float: right;
        margin-right: 10px;
        li {
          text-align: left;
          color: white;
          &:first-child {
            padding: 0 8px;
          }
        }
        .resume {
          width: calc(100% - 20px);
          margin-left: 10px;
        }
        .switch {
          margin-left: 10px;
          margin-top: 10px;
        }
      }
    }
  }
  .rwd {
    display: none;
  }
}

@media (max-width: $xl) {
  .nav {
    padding: 0 calc(50% - 620px);
    height: 60px;
    line-height: 60px;
  }
}

@media (max-width: $lg) {
  .nav {
    padding: 0 10%;
  }
}

@media (max-width: 800px) {
  .nav {
    padding: 0 5%;
  }
  .nav .normal {
    display: none;
  }
  .nav .rwd {
    display: block;
  }
}

@media (max-width: 600px) {
  .nav {
    padding: 0 20px;
  }
}

@media (max-width: 450px) {
  .nav {
    padding: 0 10px;
  }
}
