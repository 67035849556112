@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import '~material-design-icons/iconfont/material-icons.css';
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Roboto Mono', monospace;
    letter-spacing: normal;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow: overlay;
}

body::-webkit-scrollbar {
  width: 10px;
}
/* Track */
body::-webkit-scrollbar-track {
  display: none;
  background-color: transparent;
  box-shadow: inset 0 0 10px 10px transparent;
  border: solid 3px transparent;
}
/* Handle */
body::-webkit-scrollbar-thumb {
  /* background: #333;  */
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #CC3363;
  border: solid 3px transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  transition: background-color 0.2s;
}

.scrollbar {
  overflow: auto;
}

.scrollbar.horz {
  overflow-x: auto;
  overflow-y: visible;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
.scrollbar::-webkit-scrollbar-track {
  display: none;
  background-color: transparent;
  box-shadow: inset 0 0 10px 10px transparent;
  border: solid 3px transparent;
}
/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #cc3363;
  border: solid 3px transparent;
  transition: box-shadow 0.2s;
}

#nprogress .bar {
  background: #CC3363 !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px #CC3363DD, 0 0 5px #CC3363DD !important;
}