@import './variable.scss';

.container {
  position: relative;
  width: 56px;
  height: 26px;
  padding: 3px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.2s linear;
  .handler {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 3px #00000070;
    transition: box-shadow 0.2s linear, transform 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 0 5px 3px $primary;
    }
    &.light {
      transform: translateX(0);
    }
    &.dark {
      transform: translateX(30px);
    }
  }
  .icon {
    font-size: 22px;
    transform: translateY(-1px);
    filter: drop-shadow(0 0 3px #00000070);
    &.night {
      color: #839ecc;
    }
    &.day {
      color: #ffd900;
    }
  }
  &.sm {
    width: 44px;
    height: 22px;
    .handler {
      width: 18px;
      height: 18px;
      &.dark {
        transform: translateX(22px);
      }
    }
    .icon {
      font-size: 18px;
    }
  }
}
